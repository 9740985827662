@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

$primary-color: #017C2B;
$secondary-color: #2B2B2BCC;

$success-alert-color: #25A24920;

ol {
  @apply list-decimal;
}

body {
  margin: 0;
  font-family: 'Public Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondary-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-primary {
  background-color: $primary-color;
}
.text-primary {
  color: $primary-color;
}
.border-primary {
  border-color: $primary-color;
}
.text-secondary {
  color: $secondary-color;
}
.bg-secondary {
  background-color: $secondary-color;
}

#login-modal::before, #lupa-password::before { 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Efek latar belakang gelap */
}
.success-flashdata {
  background-color: $success-alert-color;
  border-left: 4px solid $primary-color;
  padding: 10px 20px;
}

.no-scrollbar::-webkit-scrollbar{
  width: 0;
}