.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* CSS untuk animasi fade */
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Gaya untuk gambar dalam slider */
.slider-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: fade 1s linear;
  opacity: 0; /* Atur opasitas awal menjadi 0 */
}

/* Gaya untuk gambar aktif (yang sedang ditampilkan) */
.slider-image.active {
  opacity: 1; /* Atur opasitas menjadi 1 untuk gambar aktif */
}
