/* DataComponent.css */

.slide-container {
    position: relative;
    width: 100%;
    height: 250px; /* Atur tinggi slide container sesuai kebutuhan */
    overflow: hidden;
    margin-bottom:50px;
  }
  
  .slide-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out; /* Animasi transisi */
  }
  
  /* Animasi untuk memasuki layar */
  .slide-enter {
    transform: translateX(-100%);
  }
  
  /* Animasi untuk ketika masuk ke layar */
  .slide-enter-active {
    transform: translateX(0);
  }
  
  /* Animasi untuk keluar dari layar */
  .slide-exit {
    transform: translateX(0);
  }
  
  /* Animasi untuk ketika keluar dari layar */
  .slide-exit-active {
    transform: translateX(100%);
  }

  .slide-container1 {
    position: relative;
    width: auto;
    overflow: hidden;
  }
  
  .slide-image1 {
    position:relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out; /* Animasi transisi */
  }